import React from "react";
import AboutHeader from "./AboutHeader";
import AboutBody from "./AboutBody";
import TypicalProjects from "../HomPage/TypicalProjects";
import OurCustomer from "./OurCustomer";

const AboutUs = () => {
    return (
        <>
            <AboutHeader />
            <AboutBody />
            <TypicalProjects />
            <OurCustomer />
        </>
    );
};

export default AboutUs;
