import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import TypicalProjectCard from "../../common/TypicalProjectCard";

// Api
import ClientCompanyApi from "../../../api/ClientCompanyApi";

const TypicalProjects = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [company, setCompany] = useState({});
  const project = company?.projects || {};

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    const { data } = await ClientCompanyApi.getCompany();
    setCompany(data);
    return data;
  };

  return (
    <>
      <div className="typical_projects_container">
        {/* <div className="header">
          <h1>
            Dự án điển hình <hr />
          </h1>
          <p className="base_text">{project.description}</p>
        </div> */}
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <div className="statistical_box">
            <div className="statistical_box-item">
              <h2 className="statistical_number">
                {counterOn && (
                  <CountUp
                    start={0}
                    end={project.completedProject}
                    duration={3}
                  />
                )}
              </h2>
              <div className="statistical_title">
                <h3>Dự án hoàn thành</h3>
                <p>
                  Chúng tôi tự hào giới thiệu những dự án đã hoàn thành mà chúng
                  tôi đã thực hiện với sự thành công và sự hài lòng của khách
                  hàng.{" "}
                </p>
              </div>
            </div>
            <div className="statistical_box-item">
              <h2 className="statistical_number">
                {counterOn && (
                  <CountUp start={0} end={project.happyClient} duration={3} />
                )}
              </h2>
              <div className="statistical_title">
                <h3>Khách hàng hài lòng</h3>
                <p>
                  Sự hài lòng của khách hàng là ưu tiên hàng đầu của chúng tôi.{" "}
                </p>
              </div>
            </div>
            <div className="statistical_box-item">
              <h2 className="statistical_number">
                {" "}
                {counterOn && (
                  <CountUp
                    start={0}
                    end={project.converingProject}
                    duration={3}
                  />
                )}
              </h2>
              <div className="statistical_title">
                <h3>Chuyển đổi dự án</h3>
                <p>
                  Khám phá những kỹ năng và giải pháp sáng tạo mà chúng tôi đem
                  lại để biến các dự án của bạn thành hiện thực.
                </p>
              </div>
            </div>
          </div>
        </ScrollTrigger>

        <div className="typical_project_imgs">
          {project?.projectDetail?.map((link, index) => (
            <div key={index} className="project-img-item">
              <TypicalProjectCard item={link} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TypicalProjects;
