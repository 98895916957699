import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import ProductDropdownMenu from "./ProductDropdownMenu";
import { Link, useLocation } from "react-router-dom";
import { seletedProductItem } from "../../utils/support";
import LanguagesOptions from "./LanguagesOptions";
import { FiChevronDown } from "react-icons/fi";
import SideMenu from "../Modal/SideMenu";
import { ROUTER } from "../../utils/router";
// Api
import ApplicationApi from "../../api/ClientApplicationApi.js";
import SolutionApi from "../../api/ClientSolutionApi.js";

const Navbar = ({menuProduct, menuSolution}) => {
  const [activeMenu, setActiveMenu] = useState("");
  const [open, setOpen] = useState(false);
  const [crrItem, setCrrItem] = useState(0);
  const [menuElms, setMenuElms] = useState([]);
  const { pathname } = useLocation();
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [dataMenu, setDataMenu] = useState([]);
  // const [menuProduct, setMenuProduct] = useState([]);
  // const [menuSolution, setMenuSolution] = useState([]);
  const handleClick = (crrId) => {
    seletedProductItem(crrId, menuElms);
    setCrrItem(crrId);
    if (crrId !== 1 && crrId !== 2) {
      // setCrrItem(crrId);
      setOpen(false);
    }
  };
  const showDopdown = (menuItems) => {
    setOpen(true);
    setDataMenu(menuItems);
  };
  const showDrawer = () => {
    setOpenSideMenu(true);
  };
  const onClose = () => {
    setOpenSideMenu(false);
  };

  useEffect(() => {
    const elms = document.querySelectorAll(".menu .item");
    setMenuElms([...elms]);
  }, []);
  useEffect(() => {
    !open &&
      !(
        pathname.includes(ROUTER.PRODUCT_DETAIL) ||
        pathname.includes(ROUTER.APPLICATION)
      ) &&
      seletedProductItem(crrItem, menuElms);
  }, [open]);
  useEffect(() => {
    switch (pathname) {
      case ROUTER.HOME:
        seletedProductItem(0, menuElms);
        break;
      case ROUTER.PRODUCT_DETAIL:
        seletedProductItem(1, menuElms);
        break;
      case ROUTER.SOLUTION_DETAIL:
        seletedProductItem(2, menuElms);
        break;
      case ROUTER.ABOUT:
        // console.log(pathname);
        seletedProductItem(3, menuElms);
        break;
      case ROUTER.CONTACT:
        seletedProductItem(4, menuElms);
        break;
    }
  }, [pathname]);

  // useEffect(() => {
  //   getListApplications();
  //   getListSolutions();
  // }, []);

  // const getListApplications = async () => {
  //   const { data } = await ApplicationApi.getList();
  //   setMenuProduct(data);
  // };

  // const getListSolutions = async () => {
  //   const { data } = await SolutionApi.getList();
  //   setMenuSolution(data)
  // };

  const logo = () => {
    return (
      <div
        onClick={() => {
          handleClick(0);
          setOpenSideMenu(false);
        }}
        className="logo_group"
      >
        <Link to={"/"}>
          <img src="/images/logo-ate.svg" alt="logo" className="logo" />
          <img
            src="/images/logo_responsive.svg"
            alt="logo"
            className="logo_responsive"
          />
        </Link>
      </div>
    );
  };

  return (
    <div className="navbar_container">
      {/* Side menu */}
      <div className="navbar_side_menu">
        <img onClick={showDrawer} src="images/ico_side_menu-open.svg" alt="" />
        <Drawer
          width={300}
          className="side_menu"
          title={logo()}
          placement="left"
          onClose={onClose}
          open={openSideMenu}
          closeIcon={<img src="images/ico_side_menu-close.svg" alt="" />}
          headerStyle={{ background: "#051829" }}
          bodyStyle={{
            background: "#051829",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <SideMenu setOpenSideMenu={setOpenSideMenu} />
        </Drawer>
      </div>

      {/* Logo */}
      {!openSideMenu && logo()}

      {/* Change languages */}
      <LanguagesOptions />

      {/* Menu */}
      <div className="navbar_menu">
        <ul className="menu" id="myMenu">
          <li onClick={() => handleClick(0)} className="item">
            <Link to={"/"}>Trang chủ</Link>
          </li>
          <li onClick={() => handleClick(1)} className="item">
            <a onClick={() => {
              setActiveMenu("product")
              showDopdown(menuProduct)}
            }>
              Sản phẩm
              <FiChevronDown
                style={{ paddingLeft: "10px", fontSize: "30px" }}
              />
            </a>
          </li>
          <li onClick={() => handleClick(2)} className="item">
            <a onClick={() => {
              setActiveMenu("solution")
              showDopdown(menuSolution)
            }}>
              Giải pháp
              <FiChevronDown
                style={{ paddingLeft: "10px", fontSize: "30px" }}
              />
            </a>
          </li>
          <li onClick={() => handleClick(3)} className="item">
            <Link to={"/about-us"} href="#">
              Về chúng tôi
            </Link>
          </li>
          <li onClick={() => handleClick(4)} className="item">
            <Link to={"/contact-us"}>Liên hệ</Link>
          </li>
        </ul>
      </div>

      {/* Search */}
      <div className="search_box">
        <input
          type="text"
          className="search_input"
          placeholder="Find information you want."
        />
      </div>

      {/* Product dropdown */}
      <ProductDropdownMenu
        activeMenu={activeMenu}
        open={open}
        setOpen={setOpen}
        dataMenu={dataMenu}
      />
    </div>
  );
};

export default Navbar;
