import React, { useEffect, useState } from "react";
import { Col, Pagination, Row } from "antd";
import { useParams } from "react-router-dom";

// Api
import ClientAccessoriesApi from "../../../api/ClientAccessoriesApi";

const Accessories = () => {
    const { productSlug } = useParams();
    const [totalItems, setTotalItems] = useState(0);
    const [listAccessories, setListAccessories] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 12,
        page: 1,
    });

    useEffect(() => {
        getListAccessoriesRequest();
    }, [productSlug, pagination]);

    const getListAccessoriesRequest = async () => {
        const { data } = await ClientAccessoriesApi.getListAccessoriesByProduct(productSlug, pagination);
        setListAccessories(data.items);
        setTotalItems(data.total);
    };

    const handlePageChange = (page) => {
        setPagination((prevState) => ({ ...prevState, page }));
    };

    return (
        <div className="accessories_wraper">
            <h1 className="title">
                PHỤ KIỆN <hr />
            </h1>
            <div className="accessories-list">
                {listAccessories.map((item, index) => {
                    return (
                        <div key={index} className="accessories-item">
                            <div className="item-image" style={{ height: "180px" }}>
                                <img src={item?.image?.url} style={{ objectFit: "cover" }} alt="" />
                            </div>
                            <h4>{item.name}</h4>
                            <p>{item.desc}</p>
                        </div>
                    );
                })}
            </div>
            <div className="pagination">
                {!!totalItems && <Pagination pageSize={pagination?.pageSize} total={totalItems} onChange={handlePageChange} />}
            </div>
        </div>
    );
};

export default Accessories;
