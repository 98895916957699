import React from "react";
import { PreventDefault } from "../../utils/support";

const RecommenCard = ({ cardItem, getDetail }) => {
    const images = cardItem.media.filter((item) => item.type === 'images');
    // console.log(images);
    // console.log('url=', images[0].url);
    return (
        <div className="recommen-card-wraper">
            <div className="recommen-card" style={{ backgroundImage: `url(${images[0]?.url})` }}>
                <div className="card-overlay">
                    <div className="card-body">
                        <div className="card-title">
                            <div className="content-img">
                                <img src="images/ico_battery_green.svg" alt="" />
                            </div>
                            <p className="application-text">{cardItem?.name}</p>
                        </div>
                    </div>

                    <div className="card-body-zoom">
                        <div className="card-title">
                            <div className="content-img">
                                <img src="images/ico_battery_green.svg" alt="" />
                            </div>
                            <p>{cardItem?.name}</p>
                        </div>
                        <div className="card-content">
                            <p>
                                {cardItem.description}
                            </p>
                            <a
                                href=""
                                onClick={(e) => {
                                    getDetail();
                                    PreventDefault(e);
                                }}
                            >
                                Chi tiết
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecommenCard;
