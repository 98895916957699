import BaseApi from "./BaseApi";

class ClientMediaApi extends BaseApi {
  constructor() {
    super({ apiPrefix: "client/media" });
  }

  getListMediaByProduct(slug, params) {
    const url = `${this.getApiPrefix()}/product/${slug}`;
    return this.get(url, { params });
  }
}

export default new ClientMediaApi();
