import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import ButtonBox from "../../common/ButtonBox";
import { useParams } from "react-router-dom";

// Api
import ClientMediaApi from "../../../api/ClientMediaApi";

const DownloadData = () => {
  const { productSlug } = useParams();
  const [totalItems, setTotalItems] = useState(0)
  const [listMedia, setListMedia] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 3,
    page: 1,
    type: "documents"
  });

  useEffect(() => {
    getListMediaRequest(pagination);
  }, [productSlug, pagination]);

  const getListMediaRequest = async (params) => {
    const { data } = await ClientMediaApi.getListMediaByProduct(
      productSlug,
      params
    );
    setListMedia(data.items);
    setTotalItems(data.total)
  };

  const handlePageChange = (page) => {
    setPagination(prevState => ({...prevState, page}))
  }

  return (
    <div className="download_db_wraper">
      <div className="download_db-header">
        <h1 className="title">
          TÀI LIỆU <hr />
        </h1>
        {/* <div className="btn-more-new">
          <ButtonBox content="More news" className="btn__surface" />
        </div> */}
      </div>
      <div className="pdf-list">
        {listMedia.map((x) => {
          return (
            <div key={x.id} className="pdf-item">
              <div className="item-imgbox">
                <img src="/images/pdf-file.png" alt="" />
              </div>
              <div className="item-textbox">
                <div className="item-file-name">
                  <p>BROCHURES, DATASHEETS</p>
                  <a href={x.url} className="item-file-download" target="_blank">
                    <img src="/images/download-btn.svg" alt="" />
                  </a>
                </div>
                <h2 className="item-title">
                  {x.name}
                </h2>
                <p className="description">
                  {x.desc}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pagination">
        {!!totalItems && <Pagination
          pageSize={pagination?.pageSize}
          total={totalItems}
          onChange={handlePageChange}
        />}
      </div>
    </div>
  );
};

export default DownloadData;
