import { Button } from "antd";
import React from "react";

const ButtonChatBox = ({setOpen}) => {
    return (
        <Button onClick={() => setOpen(true)} className="button_chatbox" type="primary">
            <div className="content">
                <img src="images/wechat.svg" alt="" />
                Chat
            </div>
        </Button>
    );
};

export default ButtonChatBox;
