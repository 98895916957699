import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import ContactForm from "./ContactForm";
import ContactInformation from "./ContactInformation";
import ContactIntro from "./ContactIntro";
import OfficesLocation from "./OfficesLocation";
import RefContext from "../../../Context/RefContext";
import ClientCompanyApi from "../../../api/ClientCompanyApi";

const ContactUs = () => {
    const [loading, setLoading] = useState("");
    const [companyData, setCompanyData] = useState({});
    const { pathname } = useLocation();

    useEffect(() => {
        setLoading(pathname);
    }, [pathname]);

    useEffect(() => {
        getCompanyData();
    }, []);

    const getCompanyData = async () => {
        const { data } = await ClientCompanyApi.getCompany();
        setCompanyData(data);
    };

    return (
        <>
            <ContactIntro />
            <ContactInformation branches={companyData.branches} email={companyData.email} />
            <ContactForm />
            <OfficesLocation branches={companyData.branches} />
        </>
    );
};

export default ContactUs;
