import React, { useEffect, useMemo, useState, useRef } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ButtonBox from "../../common/ButtonBox";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import RecommenCard from "../../common/RecommenCard";
import { ScrollTo } from "../../../utils/support";
// Api
import ClientProductApi from "../../../api/ClientProductApi";

SwiperCore.use([Autoplay]);

const Recommen = () => {
  const naviage = useNavigate();
  const [productsLatest, setProductsLatest] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const elmDetail = useRef(null);

  const selectedProduct = useMemo(() => {
    const product =
      productsLatest?.find((x) => x.id === selectedProductId) || {};
    
    if (product.media && product.media.length > 0) {
      const images = product.media.filter((item) => item.type === 'images');
      if (images.length > 0) {
        product.img = images[0].url;
      }
    }
      return product;
  }, [selectedProductId]);

  const firstProduct = productsLatest?.[0]

  useEffect(() => {
    getProductsLatest();
  }, []);

  const getProductsLatest = async () => {
    const { data } = await ClientProductApi.getProductsLatest();
    setProductsLatest(data);
    setSelectedProductId(data?.[0].id);
    return data;
  };

  const getDetail = (item) => {
    setSelectedProductId(item.id);
    ScrollTo(elmDetail.current.offsetTop);
  };

  const goToProductDetail = (product) => {
    const applicationSlug = product?.application?.[0]?.slug;
    naviage({
      pathname: `/product/${product.slug}`,
      search: createSearchParams({
        productListOf: "application",
        slug: applicationSlug,
      }).toString(),
    });
  };

  return (
    <>
      <div className="recommen_container_top">
        <div className="recommen_header">
          <div className="header_content">
            <h1 className="title">
              ĐỀ XUẤT MỚI NHẤT <hr />
            </h1>
            {/* <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </p> */}
          </div>
          <div className="header_button">
            <ButtonBox
              onClick={() => goToProductDetail(firstProduct)}
              content="Tất cả sản phẩm"
              className="btn__surface"
            />
          </div>
        </div>
        <div className="recommen_slide">
          <Swiper
            autoplay={{
              delay: 5000,
            }}
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            className="mySwiper"
          >
            {productsLatest.map((item, index) => {
              return (
                <SwiperSlide key={index} onClick={() => console.log("click")}>
                  <RecommenCard
                    cardItem={item}
                    getDetail={() => getDetail(item)}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className="recommen_container_bottom" ref={elmDetail}>
        <div className="recommen_info">
          <h1 className="title">{selectedProduct.name}</h1>
          <img src={selectedProduct.img} alt="" />
          <ButtonBox
            onClick={() => goToProductDetail(selectedProduct)}
            content="Xem thêm"
            className="btn__surface"
          />
        </div>
        <div className="recomment_decription">
          <p className="base_text product_decription">
            {selectedProduct.description}
          </p>
          <div className="feature_list">
            <div
              style={{
                whiteSpace: "pre-wrap",
                lineHeight: "1.5715",
                margin: "10px",
                fontSize: "14px",
                color: "white",
              }}
            >
              {selectedProduct.feature}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recommen;
