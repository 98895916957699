import React, { useEffect, useState } from "react";
import { seletedProductItem } from "../../../utils/support";

const ApplicationMenu = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [menuItemsElm, setMenuItemsElm] = useState([]);
    const menuItems = [
        {
            title: "Medical electronics",
            icon: "images/icon_menu_application/ico_menu_medical.svg",
        },
        {
            title: "Electric vehicle",
            icon: "images/icon_menu_application/ico_menu_electric.svg",
        },
        {
            title: "Electric vehicle",
            icon: "images/icon_menu_application/ico_menu_electric2.svg",
        },
        {
            title: "Power supply",
            icon: "images/icon_menu_application/ico_menu_power.svg",
        },
        {
            title: "Battery recycling",
            icon: "images/icon_menu_application/ico_menu_car_battery.svg",
        },
        {
            title: "Industrial electronics",
            icon: "images/icon_menu_application/ico_menu_setting.svg",
        },
        {
            title: "Research/ Education/ Others",
            icon: "images/icon_menu_application/ico_menu_education.svg",
        },
        {
            title: "Automotive electronics",
            icon: "images/icon_menu_application/ico_menu_car.svg",
        },
        {
            title: "Battery",
            icon: "images/icon_menu_application/ico_menu_battery_plus.svg",
        },
        {
            title: "Solar",
            icon: "images/icon_menu_application/ico_menu_solar.svg",
        },
        {
            title: "IoT",
            icon: "images/icon_menu_application/ico_menu_cloud_data.svg",
        },
    ];

    useEffect(() => {
        setMenuItemsElm(document.querySelectorAll(".application_menu .menu-item"));
    }, []);

    const handleClick = (crrIndex) => {
        seletedProductItem(crrIndex, menuItemsElm);
        setActiveIndex(crrIndex);
    };
    return (
        <div className="application_menu_wraper">
            {/* <div className="application_menu">
                {menuItems?.map((menuItem, index) => {
                    return (
                        <div onClick={() => handleClick(index)} key={index} className="menu-item">
                            <img src={activeIndex === index ? menuItem.icon.replace(".svg", "-active.svg") : menuItem.icon} alt="" />
                            <p>{menuItem.title}</p>
                        </div>
                    );
                })}
            </div> */}
            {/* <div className="application_videos">
                <video className="videos-intro" autoPlay muted loop>
                    <source src="/images/introduce.mp4" type="video/mp4" />
                </video>
                <div className="content">
                    <h1></h1>
                    <p></p>
                </div>
            </div> */}
        </div>
    );
};

export default ApplicationMenu;
