import BaseApi from "./BaseApi";

class ClientCompanyApi extends BaseApi {
  constructor() {
    super({ apiPrefix: "client/company" });
  }

  getCompany() {
    const url = `${this.getApiPrefix()}`;
    return this.get(url);
  }
}

export default new ClientCompanyApi();
