import { Dropdown, Space } from "antd";
import React from "react";
import { FiChevronDown } from "react-icons/fi";

const LanguagesOptions = () => {
    const items = [
        {
            label: "English",
            key: "0",
        },
        {
            label: "Vietnamese",
            key: "1",
        },
    ];

    return (
        <div className="languages_options_wraper">
            <Dropdown menu={{ items }} trigger={["click"]} className="dropdown">
                <a onClick={(e) => e.preventDefault()}>
                    <Space className="slected" align="center">
                        <img src="images/languages.png" alt="" />
                        EL
                        <FiChevronDown className="ico_dropdown"/>
                    </Space>
                </a>
            </Dropdown>
        </div>
    );
};

export default LanguagesOptions;
