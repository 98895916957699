import React, { useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import ListProduct from "../../common/ListProduct";
import { Anchor } from "antd";
import Specifications from "./Specifications";
import Featuref from "./Featuref";
import Accessories from "./Accessories";
import DownloadData from "./DownloadData";
import RelatedProduct from "./RelatedProduct";
import { useLocation } from "react-router-dom";
import { ScrollTo } from "../../../utils/support";
import { ROUTER } from "../../../utils/router";
// Api
import ClientProductApi from "../../../api/ClientProductApi.js";

const Product = () => {
    const naviage = useNavigate();
    const { productSlug } = useParams();
    const [searchParams] = useSearchParams();
    const queryProductListOf = searchParams.get("productListOf");
    const querySlug = searchParams.get("slug");
    const [totalProduct, setTotalProduct] = useState(0);
    const [productDetail, setProductDetail] = useState({});
    const [listProduct, setListProduct] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 4,
        page: 1,
    });

    const itemsMenu = [
        {
            key: "specifications",
            href: "#specifications",
            title: "Giới thiệu chung",
        },
        {
            key: "feature",
            href: "#feature",
            title: "Tính năng",
        },
        {
            key: "accessories",
            href: "#accessories",
            title: "Phụ kiện",
        },
        {
            key: "dowload_database",
            href: "#dowload_database",
            title: "Tài liệu",
        },
        // {
        //     key: "related_product",
        //     href: "#related_product",
        //     title: "Sản phẩm và giải pháp liên quan",
        // },
        // {
        //     key: "list_of_system",
        //     href: "#list_of_system",
        //     title: "Sản phẩm liên quan",
        // },
    ];

    const { pathname } = useLocation();
    const topRef = useRef(null);
    const [targetOffset, setTargetOffset] = useState();

    useEffect(() => {
        setTargetOffset(topRef.current?.clientHeight + 100);
    }, []);

    useEffect(() => {
        getProductDetailRequest();
    }, [productSlug]);

    useEffect(() => {
        if (queryProductListOf === "solution") {
            getListProductBySolutionRequest();
            return;
        }
        getListProductByApplicationRequest();
    }, [productSlug, pagination]);

    // console.log(topRef.current?.offsetTop);
    useEffect(() => {
        if (pathname === ROUTER.PRODUCT_DETAIL) {
            ScrollTo(700);
        }
    }, [pathname]);

    const getProductDetailRequest = async () => {
        const { data } = await ClientProductApi.getOne(productSlug);
        setProductDetail(data);
    };

    const setData = (data) => {
        setListProduct(
            data?.items.map((x) => ({
                ...x,
                src: x?.media?.[0]?.url,
                voltage: "30V-1200V",
                current: "5A-150A",
                power: "1850W-3000A",
            }))
        );
        setTotalProduct(data.total);
    };

    const getListProductBySolutionRequest = async () => {
        const { data } = await ClientProductApi.getListProductBySolution(querySlug, pagination);
        setData(data);
    };

    const getListProductByApplicationRequest = async () => {
        const { data } = await ClientProductApi.getListProductByApplication(querySlug, pagination);
        setData(data);
    };

    const handePageChange = (page) => {
        setPagination((prevState) => ({ ...prevState, page }));
    };

    const handleClickItem = (item) => {
        naviage({
            pathname: `/product/${item.slug}`,
            search: createSearchParams({
                productListOf: queryProductListOf,
                slug: querySlug,
            }).toString(),
        });
    };

    return (
        <div className="product_detail_wraper">
            <div className="product_detail-header">
                <h1></h1>
                <p></p>
            </div>
            <div className="product_detail-body">
                <div className="body_left">
                    <div id="specifications">
                        <Specifications productDetail={productDetail} />
                    </div>
                    <div id="feature">
                        <Featuref productDetail={productDetail} />
                    </div>
                    <div id="accessories">
                        <Accessories />
                    </div>
                    <div id="dowload_database">
                        <DownloadData />
                    </div>
                    {/* <div id="list_of_system">
                        <ListProduct
                            data={listProduct}
                            totalProduct={totalProduct}
                            pagination={pagination}
                            onChangePage={handePageChange}
                            onClickItem={handleClickItem}
                        />
                    </div> */}
                    {/* <div id="related_product">
                        <RelatedProduct />
                    </div> */}
                </div>
                <div className="body_right">
                    <div id="Sticky-menu">
                        <Anchor targetOffset={targetOffset} items={itemsMenu} />
                        <div id="index-item" ref={topRef}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
