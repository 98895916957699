import React from "react";

const AboutBody = () => {
  return (
    <div className="about_body">
      <h1 className="title">
        Giới thiệu chung <hr />
      </h1>
      <div className="about_body-middle">
        <div className="text_box">
          <p className="base_text">
            Chúng tôi chuyên sản xất các thiết bị kiểm tra tự động cho các ứng
            dụng trong nhiều lĩnh vực khác nhau từ lĩnh vực điện tử, ô tô, y
            tế... Cung cấp các giải pháp ICT, FCT, EOL giúp khách hàng đạt được
            sự ổn định và độ tin cậy cao trong quá trình sản xuất và ứng dụng
            sản phẩm. Chúng tôi cung cấp giải pháp kiểm tra hoàn chỉnh từ
            thiết kế phần cứng, Fixture và phần mềm.
          </p>
        </div>
        <div className="imgs_box">
          <div className="img-item">
            <img src="images/about_img_box_1.png" alt="" />
          </div>
          <div className="img-item">
            <img src="images/about_img_box_3.png" alt="" />
          </div>
          <div className="img-item">
            <img className="img-item" src="images/about_img_box_2.png" alt="" />
          </div>
        </div>
        <div className="text_box">
          <p className="base_text">
            Với khả năng làm chủ về phần cứng và phần mềm chúng tôi có khả năng
            cung cấp các giải pháp kiểm tra tự động theo yêu cầu cụ thể từ khách
            hàng trong thời gian ngắn.
          </p>
        </div>
        <div className="text_box">
          <p className="base_text">
            Đội ngũ kỹ sư có nhiều kinh nghiệm trong lĩnh vực kiểm tra tự động,
            chúng tôi luôn đặt chất lượng và sự hài lòng của khách hàng lên hàng
            đầu và cam kết đưa ra giải pháp tối ưu cho từng khách hàng để giúp
            tiết kiệm chi phí và tăng năng suất sản xuất.
          </p>
        </div>
      </div>
      <div className="about_body-bottom">
        <div className="words_aboutus">
          <h1>Về chúng tôi</h1>
          <p className="base_text">
            <b>Sứ mệnh</b> của công ty là đem đến các giải pháp tự động hoàn hảo
            nhất cho sản xuất board mạch điện tử, giúp tối ưu hóa hiệu suất sản
            xuất và tiết kiệm chi phí cho khách hàng.
          </p>
          <p className="base_text">
            <b>Tầm nhìn</b> của công ty là trở thành nhà cung cấp hàng đầu trong
            lĩnh vực thiết kế và sản xuất máy kiểm tra tự động cho board mạch
            điện tử, được khách hàng tin tưởng và lựa chọn.
          </p>
          <p className="base_text">
            <b>Trong tầm nhìn</b> của chúng tôi, công ty không chỉ là một nhà
            cung cấp thiết bị kiểm tra tự động chất lượng cao, mà còn là đối tác
            đồng hành trong quá trình phát triển sản phẩm của khách hàng. Chúng
            tôi cam kết luôn đồng hành, hỗ trợ khách hàng xuyên suốt quá trình
            sử dụng sản phẩm của chúng tôi, đồng thời liên tục nâng cấp, phát
            triển các model mới để đáp ứng tốt hơn nhu cầu sản xuất ngày càng
            cao của khách hàng. Chính vì thế, việc chọn lựa sản phẩm của chúng
            tôi không chỉ là chọn một sản phẩm chất lượng, giá cả hợp lý mà còn
            là chọn một đối tác đồng hành tin cậy trên con đường phát triển sản
            phẩm của quý khách hàng.
          </p>
          <p className="base_text">
            Với <b>sứ mệnh</b>, <b>tầm nhìn</b> và <b>giá trị cốt lõi</b> như
            trên, công ty của bạn hy vọng sẽ trở thành một đối tác đáng tin cậy
            và lựa chọn hàng đầu của các nhà sản xuất trong lĩnh vực điện tử.
          </p>
          <h1>Đội ngũ </h1>
          <p className="base_text">
            Đội ngũ của chúng tôi gồm những chuyên gia và kỹ sư có kinh nghiệm
            lâu năm trong lĩnh vực thiết kế và sản xuất máy kiểm tra tự động
            trong sản xuất board mạch điện tử. Chúng tôi tự hào về sự đam mê,
            chuyên nghiệp và tận tâm của mỗi thành viên trong đội ngũ.
          </p>
        </div>
        <div className="about_card">
          <div className="card-header">
            <img src="images/ico_check.svg" alt="" />
            <h2>Chất lượng</h2>
          </div>
          <p className="card-body">
            Cam kết mang đến cho khách hàng sản phẩm và dịch vụ chất lượng cao
            nhất, đáp ứng được các yêu cầu khắt khe nhất của khách hàng.
          </p>
        </div>
        <div className="about_card">
          <div className="card-header">
            <img src="images/ico_check.svg" alt="" />
            <h2>Sáng tạo</h2>
          </div>
          <p className="card-body">
            Đội ngũ nhân viên của công ty luôn đổi mới và sáng tạo, tìm kiếm các
            giải pháp mới nhằm nâng cao hiệu quả sản xuất và tiết kiệm chi phí.
          </p>
        </div>
        <div className="about_card">
          <div className="card-header">
            <img src="images/ico_check.svg" alt="" />
            <h2>Tận tâm</h2>
          </div>
          <p className="card-body">
            Đội ngũ nhân viên của công ty luôn sẵn sàng hỗ trợ khách hàng bất cứ
            lúc nào, từ việc tư vấn sản phẩm, lắp đặt, bảo trì cho đến giải
            quyết các vấn đề khó khăn trong quá trình sử dụng.
          </p>
        </div>
        <div className="about_card">
          <div className="card-header">
            <img src="images/ico_check.svg" alt="" />
            <h2>Giá cả hợp lý</h2>
          </div>
          <p className="card-body">
            Công ty cam kết mang đến giá cả hợp lý và cạnh tranh so với các nhà
            cung cấp ngoại quốc, đồng thời đảm bảo chất lượng sản phẩm và dịch
            vụ.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutBody;
