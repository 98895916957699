import { Button, Checkbox, Col, Input, Radio, Row, notification } from "antd";
import React, { useState } from "react";
import SelectBox from "../../common/SelectBox";
import TextArea from "antd/es/input/TextArea";

// Api
import ClientMailApi from "../../../api/ClientMailApi";

const ContactForm = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [dataForm, setDataForm] = useState({
    name: "",
    email: "",
    company: "",
    jobTitle: "",
    message: "",
    type: "contact"
  });

  const isFormComplete = Object.values(dataForm).every((value) => value !== "");

  const handleChange = (name, value) => {
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(dataForm.email);

    // console.log('Dữ liệu gửi đi:', dataForm);
    if (isFormComplete) {
      if(isValidEmail == false){
        api.warning({
          key: 1,
          message: "Email không hợp lệ!",
          placement: "topRight",
        });
      }else{
        setIsLoading(true);
        const { data } = await ClientMailApi.createContact(dataForm);
        setIsLoading(false);
        setDataForm({
          name: "",
          email: "",
          company: "",
          jobTitle: "",
          message: "",
          type: "contact"
        });
        api.destroy(1);
        if(data.success == true){
          api.success({
            key: 1,
            message: data.data.message,
            placement: "topRight",
          });
        }else{
          api.error({
            key: 1,
            message: data.data.message,
            placement: "topRight",
          });
        }
      }
    }else{
      api.warning({
        key: 1,
        message: "Vui lòng nhập đủ thông tin",
        placement: "topRight",
      });
    }
  };

  return (
    <div className="contact_form_container">
      {contextHolder}
      <div className="form_contact">
        <div className="form-header">
          <h1 className="title">
            Yêu cầu hỗ trợ? <hr />
          </h1>
          <p className="base_text">
            Liên hệ với chúng tôi để nhận được sự hỗ trợ tốt nhất cho mọi yêu
            cầu của bạn. Vui lòng để lại tin nhắn và chúng tôi sẽ liên lạc với
            bạn ngay. Cảm ơn.
          </p>
        </div>
        <div className="input_group">
          <label className="required label">Họ và tên</label>
          <Input
            className="input"
            type="text"
            value={dataForm.name}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Email</label>
          <Input
            type="email"
            value={dataForm.email}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Số điện thoại</label>
          <Input
            className="input"
            type="number"
            value={dataForm.jobTitle}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("jobTitle", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Công ty</label>
          <Input
            className="input"
            type="text"
            value={dataForm.company}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("company", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Chi tiết về yêu cầu của bạn</label>
          <TextArea
            className="input"
            value={dataForm.message}
            rows={2}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("message", e.target.value)}
          />
        </div>
        <div className="form-submit">
          <Button
            type="submit"
            className="submit_btn"
            loading={isLoading}
            onClick={handleSubmit}
            // disabled={!isFormComplete}
          >
            Gửi
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
