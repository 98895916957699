import { useEffect, useState, useRef } from "react";
import { notification } from "antd";
import "./App.scss";
import LandingPage from "./component/LandingPage";
import ButtonChatBox from "./component/common/ButtonChatBox";
import { useLocation } from "react-router-dom";
// import Chat from "./component/Modal/Chat";
import ModalBox from "./component/Modal/ModalBox";
import { ROUTER } from "./utils/router";
import Chat from "./component/Modal/Chat";

// Api
import ClientMailApi from "./api/ClientMailApi";

function App() {
  const { pathname } = useLocation();
  const refChat = useRef();
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (pathname !== ROUTER.PRODUCT_DETAIL) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const handleSubmit = async () => {
    const dataForm = refChat.current.dataForm;
    dataForm.type = "contact";
    // console.log('Dữ liệu gửi đi:', dataForm);
    const isValid =
      !!Object.keys(dataForm).length &&
      Object.keys(dataForm).every((key) => !!dataForm[key]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(dataForm.email);

    if (isValid) {
      if(isValidEmail == false){
        api.warning({
          key: 1,
          message: "Email không hợp lệ!",
          placement: "topRight",
        });
      }else{
        setIsLoading(true);
        const { data } = await ClientMailApi.createContact(dataForm);
        setIsLoading(false);
        refChat.current?.setDataForm({
          name: "",
          email: "",
          company: "",
          jobTitle: "",
          message: "",
          type: "contact"
        });
        api.destroy(1);
        if(data.success == true){
          api.success({
            key: 1,
            message: data.data.message,
            placement: "topRight",
          });
        }else{
          api.error({
            key: 1,
            message: data.data.message,
            placement: "topRight",
          });
        }
        setOpen(false)
      }
    }else{
      api.warning({
        key: 1,
        message: "Vui lòng nhập đủ thông tin",
        placement: "topRight",
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <LandingPage />
      <ButtonChatBox setOpen={setOpen} />
      <ModalBox
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        title="Yêu cầu hỗ trợ"
        button="Gửi"
        onSubmit={handleSubmit}
        content={<Chat ref={refChat} />}
      />
    </div>
  );
}

export default App;
