import React from 'react'
import Introduction from './Introduction'
import Recommen from './Recommen'
import WeOffer from './WeOffer'
import TypicalProjects from './TypicalProjects'
import JoinNow from './JoinNow'

const HomePage = () => {
    return (
        <>
            <Introduction/>
            <Recommen/>
            <WeOffer/>
            <TypicalProjects/>
            {/* <JoinNow/> */}
        </>
    )
}

export default HomePage