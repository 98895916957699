import { Button, Modal } from "antd";
import React from "react";

const ModalBox = ({ open, setOpen, isLoading, onSubmit, ...props }) => {
  return (
    <Modal
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={1000}
    >
      <div className="modal_wraper">
        <div className="modal_header">
          <h1 className="title">
            {props.title} <hr />
          </h1>
        </div>

        <div className="modal_body">{props.content}</div>

        {props.button && (
          <div className="modal_footer">
            <div className="submit_btn">
              <Button
                type="primary"
                className="btn"
                loading={isLoading}
                onClick={onSubmit}
              >
                {props.button}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalBox;
