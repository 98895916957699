import BaseApi from "./BaseApi";

class ClientProductApi extends BaseApi {
  constructor() {
    super({ apiPrefix: "client/products" });
  }

  getListProductBySolution(slug, params) {
    const url = `${this.getApiPrefix()}/solution/${slug}`;
    return this.get(url, { params });
  }

  getListProductByApplication(slug, params) {
    const url = `${this.getApiPrefix()}/application/${slug}`;
    return this.get(url, { params });
  }

  getRelatedProduct(slug, params) {
    const url = `${this.getApiPrefix()}/application/${slug}/related`;
    return this.get(url, { params });
  }

  getRelatedSolution(slug, params) {
    const url = `${this.getApiPrefix()}/solution/${slug}/related`;
    return this.get(url, { params });
  }

  getProductsLatest() {
    const url = `${this.getApiPrefix()}/latest`;
    return this.get(url);
  }

}

export default new ClientProductApi();
