import React, { useMemo } from "react";

const ContactInformation = (props) => {
  const { branches = [], email } = props;
  // console.log(branches);
  
  const getMainBranch = useMemo(() => {
    const mainBranch = branches?.find((x) => x.isMainBranch === true) || {};
    return mainBranch;
  }, [branches]);
  const mapUrl =
    "https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=" +
    getMainBranch.address +
    "&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed";
  const mapUrl2 = mapUrl.replace("watch?v=", "v/");
  // console.log(mapUrl2);

  return (
    <div className="contact_body">
      <h1 className="title">
        Thông tin liên hệ
        <hr />
      </h1>
      <div className="contact_card_container">
        <div className="contact_card">
          <img className="card_img" src="images/ico_contact-phone.svg" alt="" />
          <div className="card_content">
            <h3>Hotline</h3>
            <p>{getMainBranch.phone}</p>
          </div>
        </div>
        <div className="contact_card">
          <img className="card_img" src="images/ico_contact-email.svg" alt="" />
          <div className="card_content">
            <h3>Email</h3>
            <p>{email}</p>
          </div>
        </div>
        <div className="contact_card">
          <img
            className="card_img"
            src="images/ico_contact-address.svg"
            alt=""
          />
          <div className="card_content">
            <h3>Địa chỉ</h3>
            <p>{getMainBranch.address}</p>
          </div>
        </div>
        <div className="contact_card">
          <img
            className="card_img"
            src="images/ico_contact-oclock.svg"
            alt=""
          />
          <div className="card_content">
            <h3>Thời gian làm việc</h3>
            <p>8:00 AM - 5:30 PM, Thứ 2 đến thứ 7</p>
          </div>
        </div>
      </div>
      <div className="map_container">
        <iframe
          className="map"
          src='https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=75 Khánh An 2, Liên Chiểu, Đà Nẵng&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
          loading="lazy"
        >
          <div className="overlay"></div>
        </iframe>
      </div>
    </div>
  );
};

export default ContactInformation;
