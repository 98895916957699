import { Carousel, Col, Row, Space } from "antd";
import React, { useState, useEffect } from "react";
import ButtonBox from "../../common/ButtonBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// const Introduction = () => {
//   const navigation = useNavigate();
//   const [banners, setBanners] = useState([]);
//   const [currentBanner, setCurrentBanner] = useState(null);
//   const defaultBannerImage = "/images/default_banner.jpg"; // Path to the default banner image

//   useEffect(() => {
//     fetchBanners();
//   }, []);

//   const fetchBanners = async () => {
//     try {
//       const response = await axios.get(
//         "http://localhost:3000/api/v1/client/banner/home"
//       );
//       const data = response.data;
//       console.log(data);
//       if (data.success && data.data.length > 0) {
//         setBanners(data.data);
//         setCurrentBanner(data.data[0]);
//         console.log(banners);
//         console.log(currentBanner);
//       }
//     } catch (error) {
//       console.error("Error fetching banners:", error);
//     }
//   };

//   const handleBannerChange = (banner) => {
//     setCurrentBanner(banner);
//   };

//   return (
//     <Row className="introduction_container">
//       <Col span={24}>
//         {currentBanner && (
//           <div className="carousel_text">
//             <h1 className="title">{currentBanner.name}</h1>
//             <p className="content">{currentBanner.desc}</p>
//             <Space size={8} className="btn_group">
//               <ButtonBox
//                 onClick={() => navigation("/about-us")}
//                 content="About us"
//                 className="btn__surface"
//               />
//               {/* <ButtonBox content="Explore" className="btn__primary" /> */}
//             </Space>
//           </div>
//         )}
//         <Carousel
//           className="carousel_item_box"
//           autoplay
//           beforeChange={handleBannerChange}
//         >
//           {banners.map((banner) => (
//             <div
//               key={banner.id}
//               className="carousel_text"
//               style={{
//                 // backgroundImage: `url(${banner?.image})`,
//                 backgroundImage: 'url(../../images/about_header_bg.png)',
//                 backgroundPosition: "center",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//               }}
//             ></div>
//           ))}
//         </Carousel>
//       </Col>
//     </Row>
//   );
// };

const Introduction = () => {
  return (
    <div className="introduction_container">
    </div>
  );
};

export default Introduction;
