import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import ApplicationMenu from "./ApplicationMenu";
import MedicalElectronics from "./MedicalElectronics";
import ApplicationProductList from "./ApplicationProductList";
// Api
import ClientSolutionApi from "../../../api/ClientSolutionApi.js";
import ClientProductApi from "../../../api/ClientProductApi.js";

const Applicatiion = () => {
  const { slug } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [solutionDetail, setSolutionDetail] = useState({});
  const [listProduct, setListProduct] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 8,
    total: 0,
  });

  useEffect(() => {
    getSolutionDetailRequest();
  }, [slug]);

  useEffect(() => {
    getListProductBySolutionRequest();
  }, [slug, pagination]);

  const getSolutionDetailRequest = async () => {
    const { data } = await ClientSolutionApi.getOne(slug);
    setSolutionDetail(data);
  };

  const getListProductBySolutionRequest = async () => {
    const { data } = await ClientProductApi.getListProductBySolution(
      slug,
      pagination
    );
    setListProduct(
      data?.items.map((x) => {
        const imageUrl = Array.isArray(x?.media)
          ? x?.media.find((item) => item.type === "images")?.url
          : null;

        return {
          ...x,
          src: imageUrl,
          voltage: "30V-1200V",
          current: "5A-150A",
          power: "1850W-3000A",
        };
      })
    );
    setTotalItems(data.total);
  };

  const handePageChange = (page) => {
    setPagination((prevState) => ({ ...prevState, page }));
  };

  return (
    <>
      <ApplicationMenu />
      <MedicalElectronics solution={solutionDetail} />
      {/* <ApplicationProductList
        listProduct={listProduct}
        totalItems={totalItems}
        pagination={pagination}
        onChangePage={handePageChange}
      /> */}
    </>
  );
};

export default Applicatiion;
