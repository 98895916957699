import React, { useEffect, useState } from "react";
import ButtonBox from "../../common/ButtonBox";
import FindYourSolutionCard from "../../common/FindYourSolutionCard";
import { useNavigate } from "react-router-dom";
// Api
import ClientSolutionApi from "../../../api/ClientSolutionApi";

const WeOffer = () => {
  const navigation = useNavigate();
  const [solutions, setSolutions] = useState([]);

  useEffect(() => {
    getProductsLatest();
  }, []);

  const getProductsLatest = async () => {
    const { data } = await ClientSolutionApi.getList();
    setSolutions(data);
    return data;
  };

  return (
    <>
      <div className="we_offer_container">
        <div className="offer_colum">
          <h1 className="title">
            CHÚNG TÔI CUNG CẤP GÌ? <hr />
          </h1>
          <div className="btn-view-mobie">
            {/* <ButtonBox
              onClick={() => navigation("/application")}
              content="View more"
              className="btn__surface "
            /> */}
          </div>
          <div className="content">
            <p className="base_text">
              Chúng tôi chuyên sản xất các thiết bị kiểm tra tự động cho các ứng
              dụng trong nhiều lĩnh vực khác nhau từ lĩnh vực điện tử, ô tô, y
              tế... Cung cấp các giải pháp ICT,FCT, EOL giúp khách hàng đạt được
              sự ổn định và độ tin cậy cao trong quá trình sản xuất và ứng dụng
              sản phẩm.
            </p>
            <p className="base_text">
              Chúng tôi cung cấp giải pháp kiểm tra hoàn chỉnh từ thiết kế phần
              cứng , Fixture và phần mềm.
            </p>
            <p className="base_text">
              Với khả năng làm chủ về phần cứng và phần mềm chúng tôi có khả
              năng cung cấp các giải pháp kiểm tra tự động theo yêu cầu cụ thể
              từ khách hàng trong thời gian ngắn.
            </p>
            <p className="base_text">
              Đội ngũ kỹ sư có nhiều kinh nghiệm trong lĩnh vực kiểm tra tự
              động, chúng tôi luôn đặt chất lượng và sự hài lòng của khách hàng
              lên hàng đầu và cam kết đưa ra giải pháp tối ưu cho từng khách
              hàng để giúp để kiệm chi phí và tăng năng suất sản xuất.
            </p>
          </div>
          {/* <div className="btn-view">
            <ButtonBox
              onClick={() => navigation("/application")}
              content="View more"
              className="btn__surface "
            />
          </div> */}
        </div>
        <div className="offer_colum">
          <div className="post">
            <div className="post_title">
              <img src="images/ico_tag.svg" alt="" />
              <h2>Chất lượng</h2>
            </div>
            {/* <div className="post_detail-mobie">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
            <div className="post_content">
              <p className="base_text">
                Cam kết mang đến cho khách hàng sản phẩm và dịch vụ chất lượng
                cao nhất, đáp ứng được các yêu cầu khắt khe nhất của khách hàng.{" "}
              </p>
            </div>
            {/* <div className="post_detail">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
          </div>
          <div className="post">
            <div className="post_title">
              <img src="images/ico_map.svg" alt="" />
              <h2>Sáng tạo</h2>
            </div>
            {/* <div className="post_detail-mobie">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
            <div className="post_content">
              <p className="base_text">
                Đội ngũ nhân viên của công ty luôn đổi mới và sáng tạo, tìm kiếm
                các giải pháp mới nhằm nâng cao hiệu quả sản xuất và tiết kiệm
                chi phí.{" "}
              </p>
            </div>
            {/* <div className="post_detail">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
          </div>
        </div>
        <div className="offer_colum">
          <div className="post">
            <div className="post_title">
              <img src="images/ico_map_x.svg" alt="" />
              <h2>Tận tâm</h2>
            </div>
            {/* <div className="post_detail-mobie">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
            <div className="post_content">
              <p className="base_text">
                Đội ngũ nhân viên của công ty luôn sẵn sàng hỗ trợ khách hàng
                bất cứ lúc nào, từ việc tư vấn sản phẩm, lắp đặt, bảo trì cho
                đến giải quyết các vấn đề khó khăn trong quá trình sử dụng.{" "}
              </p>
            </div>
            {/* <div className="post_detail">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
          </div>
          <div className="post">
            <div className="post_title">
              <img src="images/ico_global.svg" alt="" />
              <h2>Giá cả hợp lý</h2>
            </div>
            {/* <div className="post_detail-mobie">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
            <div className="post_content">
              <p className="base_text">
                Công ty cam kết mang đến giá cả hợp lý và cạnh tranh so với các
                nhà cung cấp ngoại quốc, đồng thời đảm bảo chất lượng sản phẩm
                và dịch vụ.{" "}
              </p>
            </div>
            {/* <div className="post_detail">
              <a href="">Details</a>
              <img src="images/arrow_right.svg" alt="" />
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="your_solution_container">
        <div className="your_solution_header">
          <h1 className="title">
            Giải pháp cho bạn <hr />
          </h1>
          <p className="base_text">
            Khám phá các giải pháp đa dạng và tìm ra lời giải hoàn hảo cho nhu
            cầu của bạn tại đây.
          </p>
        </div>
        <div className="solution-img-box">
          {solutions.slice(0, 6).map((x) => {
            return (
              <div className="img-item" key={x.id}>
                <FindYourSolutionCard solution={x} imgLink={x.image?.url} />
              </div>
            );
          })}
        </div>
      </div> */}
    </>
  );
};

export default WeOffer;
