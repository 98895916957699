import BaseApi from './BaseApi';

class ClientSolutionApi extends BaseApi {
  constructor() {
    super({apiPrefix: 'client/solutions'})
  }

  getRelatedSolutionProduct(slug, params) {
    const url = `${this.getApiPrefix()}/product/${slug}/related`;
    return this.get(url, { params });
  }
}

export default new ClientSolutionApi();
