import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { seletedProductItem } from "../../utils/support";

const ProductDropdownMenu = ({ activeMenu, open, setOpen, dataMenu }) => {
  const naviage = useNavigate();
  const [menuElms, setMenuElms] = useState([]);
  const [productDetail, setProductDetail] = useState();
  const [linkImg, setLinkImg] = useState("");

  // handle select item
  const handleOnclick = (currentIndex) => {
    seletedProductItem(currentIndex, menuElms);
    setProductDetail(dataMenu[currentIndex]);
    setLinkImg(dataMenu[currentIndex]?.image?.url);
  };

  // handle onmouse item of sub menu
  const handleOnMouse = (itemSubMenu) => {
    setLinkImg(itemSubMenu?.image?.url);
  };

  const handleClick = (item) => {
    const pathname = activeMenu === "product" ? `application/${item.slug}` : `solution/${item.slug}`
    naviage({pathname})
    setOpen(false)
  }

  useEffect(() => {
    const elms = document.querySelectorAll(".product_item");
    setMenuElms([...elms]);
    seletedProductItem(0, elms);
    setProductDetail(dataMenu?.[0]);
    setLinkImg(dataMenu?.[0]?.image?.url);
  }, [dataMenu]);

  return (
    <div className={`product_menu_box ${open ? "show" : "hide"}`}>
      <div className="product_menu">
        <ul className="item_list">
          {dataMenu?.map((item, index) => {
            return (
              <li key={index}>
                <div
                  onClick={() => handleOnclick(index, item)}
                  className={`product_item`}
                >
                  <p>{item?.name}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="product_detail">
          <a onClick={() => setOpen(false)} href="#" className="close-btn">
            <img src="/images/ico_close.svg" alt="" />
          </a>
          <h1 className="title">
            {productDetail?.name} <hr />
          </h1>
          <div className="body">
            <div className="decription">
              <p className="base_text">{productDetail?.desc}</p>
              {productDetail?.children?.map((item, index) => (
                <a
                  key={index}
                  onMouseOver={() => handleOnMouse(item)}
                  onMouseOut={() => handleOnMouse(productDetail)}
                  onClick={() => handleClick(item)}
                >
                  {item?.name}
                </a>
              ))}
            </div>
            <div className="image">
              <img src={linkImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDropdownMenu;
