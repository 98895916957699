import BaseApi from "./BaseApi";

class ClientBannerApi extends BaseApi {
  constructor() {
    super({ apiPrefix: "client/banner" });
  }

  getBannerHome() {
    const url = `${this.getApiPrefix()}/home`;
    return this.get(url);
  }

  getBannerAboutUs() {
    const url = `${this.getApiPrefix()}/aboutus`;
    return this.get(url);
  }

  getBannerContactUs() {
    const url = `${this.getApiPrefix()}/contactus`;
    return this.get(url);
  }

  getBannerApplication(slug) {
    const url = `${this.getApiPrefix()}/application/${slug}}`;
    return this.get(url);
  }

  getBannerSolution(slug) {
    const url = `${this.getApiPrefix()}/solution/${slug}}`;
    return this.get(url);
  }
}

export default new ClientBannerApi();
