import React from "react";

const ContactIntro = () => {
  return (
    <div className="contact_header">
      {/* <h1>Liên hệ</h1>
      <p>
        Liên hệ với chúng tôi để khám phá thêm về sản phẩm, nhận báo giá chi
        tiết và tìm hiểu cách chúng tôi có thể đáp ứng nhu cầu của bạn. Chúng
        tôi sẵn lòng lắng nghe và cung cấp thông tin chính xác để đảm bảo sự hài
        lòng của khách hàng.
      </p> */}
    </div>
  );
};

export default ContactIntro;
