import { Col, Row } from "antd";
import React from "react";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import LanguagesOptions from "./LanguagesOptions";

const Header = (props) => {
  const { branch, email } = props;
  return (
    <Row className="header_container">
      <Col span={12} className="header_left">
        <div className="phone_group">
          <FiPhone className="phone_icon" />
          <p className="phone_number">{branch.phone}</p>
        </div>
        <div className="email_group">
          <FiMail className="email_icon" />
          <p className="email_address">{email}</p>
        </div>
        <div className="address_group">
          <FiMapPin className="address_icon" />
          <p className="address">{branch.address}</p>
        </div>
      </Col>
      {/* <Col span={12} className="header_right">
        <LanguagesOptions />
      </Col> */}
    </Row>
  );
};

export default Header;
