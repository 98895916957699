import React, { useState } from "react";
import ModalBox from "../../Modal/ModalBox";
import TechnicalArticles from "../../Modal/TechnicalArticles";
// Constants
import { SOLUTION_ATTECHMENT_TYPES } from "../../../utils/constants";
const { VIDEO, TECHNICAL_TECHNICAL_ARTICLES, PRODUCT_CATALOG } =
  SOLUTION_ATTECHMENT_TYPES;

const MedicalElectronics = ({ solution }) => {
  const [modalType, setModalType] = useState(null);
  return (
    <>
      <div className="medical_electronics_wraper">
        
        <h1 className="title">
          {solution.name} <hr />
        </h1>
        <p
          className="base_text"
          dangerouslySetInnerHTML={{ __html: solution.post }}
        ></p>

      <div>
          <h1 className="title">
              TÀI LIỆU <hr />
        </h1>
      </div>
      <div className="medical_menu">
          <div onClick={() => setModalType(VIDEO)} className="medical-item">
            <img src="/images/ico_medical_videos.svg" alt="" />
            <p>UCT video</p>
          </div>
          {/* <div
            onClick={() => setModalType(TECHNICAL_TECHNICAL_ARTICLES)}
            className="medical-item"
          >
            <img src="/images/ico_medical_zip.svg" alt="" />
            <p>Related technical articles</p>
          </div> */}
          <div
            onClick={() => setModalType(PRODUCT_CATALOG)}
            className="medical-item"
          >
            <img src="/images/ico_medical_carbon.svg" alt="" />
            <p>Product catalog</p>
          </div>
        </div>

      </div>
      <ModalBox
        open={!!modalType}
        setOpen={() => setModalType(null)}
        title="Related technical document"
        content={
          <TechnicalArticles modalType={modalType} solution={solution} />
        }
      />
    </>
  );
};

export default MedicalElectronics;
