import React from "react";

const OfficeLocationCard = (item) => {
    const location = item.cardItem;
    return (
        <div className="office_card">
            <div
                className="card_img"
                style={{
                    backgroundImage: `url(${location.image})`,
                }}
            >
                <div className="overlay">
                    <div className="content">
                        <h6 className="name">{location.name}</h6>
                        <div className="phone">
                            <img src="images/ico_phone_card.svg" alt="" />
                            <p>{location.phone}</p>
                        </div>
                        <div className="location">
                            <img src="images/ico_map_card.svg" alt="" />
                            <p>{location.address}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfficeLocationCard;
