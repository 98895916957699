import React from "react";

// Constants
import { SOLUTION_ATTECHMENT_TYPES } from "../../utils/constants";
const { VIDEO, TECHNICAL_TECHNICAL_ARTICLES } = SOLUTION_ATTECHMENT_TYPES;

const TechnicalArticles = ({ modalType, solution }) => {
  const renderComponent = () => {
    switch (modalType) {
      case VIDEO:
        return (
          <iframe
            width="100%"
            height="100%"
            src={solution.videoAttachment}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowfullscreen
          ></iframe>
        );
      case TECHNICAL_TECHNICAL_ARTICLES:
        return (
          <ul>
            {solution.technicalArticleAttachments?.map((x) => {
              return (
                <li key={x.id}>
                  <a href={x.url}>{x.name}</a>
                </li>
              );
            })}
          </ul>
        );
      default:
        return (
          <ul>
            {solution.catalogAttachments?.map((x) => {
              return (
                <li key={x.id}>
                  <a href={x.url}>{x.name}</a>
                </li>
              );
            })}
          </ul>
        );
    }
  };

  return <div className="technical_articles">{renderComponent()}</div>;
};

export default TechnicalArticles;
