import { useEffect } from "react";
import { useParams, createSearchParams, useNavigate } from "react-router-dom";

// Api
import ClientProductApi from "../../../api/ClientProductApi.js";

const ApplicationDetail = () => {
  const { slug } = useParams();
  const naviage = useNavigate();

  useEffect(() => {
    getListProductByApplicationRequest();
  }, [slug]);

  const getListProductByApplicationRequest = async () => {
    const { data } = await ClientProductApi.getListProductByApplication(slug);
    const firstProduct = data?.items?.[0];
    if (firstProduct) {
      naviage({
        pathname: `/product/${firstProduct.slug}`,
        search: createSearchParams({
          productListOf: "application",
          slug,
        }).toString(),
      });
    }
  };

  return null;
};

export default ApplicationDetail;
