import BaseApi from "./BaseApi";

class ClientAccessoriesApi extends BaseApi {
  constructor() {
    super({ apiPrefix: "client/accessories" });
  }

  getListAccessoriesByProduct(slug, params) {
    const url = `${this.getApiPrefix()}/${slug}`;
    return this.get(url, { params });
  }
}

export default new ClientAccessoriesApi();
