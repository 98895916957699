import React from 'react'

const OurCustomer = () => {
    return (
        <div className='our_customer'>
            <h1 className='title'>Khách hàng của chúng tôi <hr /></h1>
            <div className='customer_list'>
                <img src="images/ourCustomer.png" alt="" />
            </div>
        </div>
    )
}

export default OurCustomer