import React from "react";

const TypicalProjectCard = ({ item }) => {
  return (
    <div className="typical_project_card">
      <div
        className="project-card-bg"
        style={{ backgroundImage: `url(${item.image})` }}
      >
        <div className="project-card-body">
          <div className="over-lay">
            <div className="project-name">
              <img src="/images/ico_project.svg" alt="" />
              <h2>{item.name}</h2>
            </div>
            <p className="project-description">{item.shortDesc}</p>
          </div>
          <div className="over-lay zoom">
            <div className="project-name">
              <img src="/images/ico_project.svg" alt="" />
              <h2>{item.name}</h2>
            </div>
            <p className="project-description">{item.shortDesc}</p>
            <p className="project-content">{item.longDesc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypicalProjectCard;
