import { Button } from "antd";
import React from "react";

const ButtonBox = ({ content, className, onClick }) => {
    return (
        <Button onClick={onClick} type="primary" className={`btn ${className}`}>
            {content}
        </Button>
    );
};

export default ButtonBox;
