import React from "react";

const Featuref = ({ productDetail }) => {
  return (
    <div className="featuref_wraper">
      <div className="featuref_content">
        <h1 className="title">TÍNH NĂNG</h1>
        {/* <h3 className="sub-title">Thông tin tính năng</h3> */}
        <div
          style={{
            whiteSpace: "pre-wrap",
            lineHeight: "1.5715",
            marginBottom: "20px",
            marginLeft: "20px",
            fontSize: "14px",
            color: "#051829"
          }}
        >
          {productDetail.feature}
        </div>
        {/* <h3 className="sub-title">Thông số kỹ thuật</h3> */}
        <p style={{
            whiteSpace: "pre-wrap",
            lineHeight: "1.5715",
            marginBottom: "0px",
            marginLeft: "20px",
            fontSize: "14px",
            color: "#051829"
          }} dangerouslySetInnerHTML={{__html: productDetail.function}}></p>
      </div>
    </div>
  );
};

export default Featuref;
