export const seletedProductItem = (crIindex, listElm) => {
    listElm[crIindex]?.classList.add("active");
    if (crIindex === null) {
        listElm.forEach((item) => item.classList.remove("active"));
    }
    listElm.forEach((item, index) => {
        if (index !== crIindex) {
            item.classList.remove("active");
        }
    });
};

export const PreventDefault = (event) => event.preventDefault();

export const ScrollTo = (position) => {
    window.scrollTo({
        top: position,
        behavior: "smooth"
    })
}
