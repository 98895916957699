import React, { useEffect, useState, useMemo, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Col, Row , notification} from "antd";
import ButtonBox from "../../common/ButtonBox";
import ModalBox from "../../Modal/ModalBox";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../App.scss";
import { ROUTER } from "../../../utils/router";
import { useLocation } from "react-router-dom";
import GetAQuote from "../../Modal/GetAQuote";
import Quote from "../../Modal/Quote";

// Api
import ClientMailApi from "../../../api/ClientMailApi";
import Chat from "../../Modal/Chat";

const Specifications = ({ productDetail }) => {
    const { pathname } = useLocation();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [open, setOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const refChat = useRef();

    const listMedia = useMemo(() => {
        const data = productDetail?.media?.filter((x) => x.type === "images") || [];
        return data;
    }, [productDetail?.media]);

    const buttonPrice = () => (
        <div className="btn_price">
            <img src="/images/ico_dollar.svg" alt="ico" />
            <p>Product price</p>
        </div>
    );

    useEffect(() => {
        if (pathname !== ROUTER.PRODUCT_DETAIL) {
          window.scrollTo(0, 0);
        }
      }, [pathname]);

    const handleSubmit = async () => {
        const dataForm = refChat.current.dataForm;
        // console.log('Dữ liệu gửi đi:', dataForm);
        const isValid =
          !!Object.keys(dataForm).length &&
          Object.keys(dataForm).every((key) => !!dataForm[key]);
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(dataForm.email);
    
        if (isValid) {
          if(isValidEmail == false){
            api.warning({
              key: 1,
              message: "Email không hợp lệ!",
              placement: "topRight",
            });
          }else{
            setIsLoading(true);
            const { data } = await ClientMailApi.createQuote(dataForm);
            setIsLoading(false);
            refChat.current?.setDataForm({
                name: "",
                email: "",
                company: "",
                jobTitle: "",
                message: "",
                type: "quote"
            });
            api.destroy(1);
            if(data.success == true){
              api.success({
                key: 1,
                message: data.data.message,
                placement: "topRight",
              });
            }else{
              api.error({
                key: 1,
                message: data.data.message,
                placement: "topRight",
              });
            }
            setOpen(false)
          }
        }else{
          api.warning({
            key: 1,
            message: "Vui lòng nhập đủ thông tin",
            placement: "topRight",
          });
        }
      };

    return (
        <div className="specifications_wraper">
            {contextHolder}
            <Row>
                <Col span={24}>
                    <h1 className="title">
                    GIỚI THIỆU CHUNG
                        <hr />
                    </h1>
                </Col>
                <Col span={10}>
                    <div className="specifications_slide">
                        {!!listMedia.length && (
                            <Swiper
                                style={{
                                    "--swiper-navigation-color": "#fff",
                                    "--swiper-pagination-color": "#fff",
                                }}
                                loop={true}
                                spaceBetween={10}
                                navigation={true}
                                thumbs={{
                                    swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                                }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2"
                            >
                                {listMedia.map((x) => {
                                    return (
                                        <SwiperSlide key={x.id}>
                                            <img
                                                src={x.url}
                                                style={{ height: "150px", width: "100%", objectFit: "contain" }}
                                                alt="product"
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        )}
                        {!!listMedia.length && (
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={true}
                                spaceBetween={10}
                                slidesPerView={3}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper"
                            >
                                {listMedia.map((x) => {
                                    return (
                                        <SwiperSlide key={x.id}>
                                            <img
                                                src={x.url}
                                                style={{ height: "45px", width: "100%", objectFit: "contain" }}
                                                alt="product"
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        )}
                    </div>
                </Col>
                <Col span={14}>
                    <div className="specifications_description">
                        <h2>{productDetail.name}</h2>
                        <div className="modle_box">
                            <div className="modle">
                                <p>
                                    Model: <span>{productDetail.model}</span>
                                </p>
                            </div>
                            <div className="inventory">
                                <p>
                                    In Stock: <span>{productDetail.inventory}</span>
                                </p>
                            </div>
                        </div>
                        <p>{productDetail.description}</p>
                        <div onClick={() => setOpen(true)} className="product_price">
                            <ButtonBox content={buttonPrice()} className={"btn__surface btn"} />
                        </div>
                    </div>
                </Col>
            </Row>
            <ModalBox 
                open={open} 
                setOpen={setOpen} 
                isLoading={isLoading}
                title="Nhận báo giá" 
                button="Gửi" 
                onSubmit={handleSubmit} 
                content={<Quote model={productDetail.model} ref={refChat}/>} />
        </div>
    );
};

export default Specifications;
