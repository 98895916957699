import React from "react";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";

const Footer = (props) => {
    const { branch, email, socials, firstProduct, firstSolution } = props;

    return (
        <div className="footer_container">
            <div className="footer-box-item">
                <div className="footer_logo">
                    <img src="/images/logo-ate-white.svg" alt="logo" />
                </div>
                <div className="footer_contact">
                    <div className="phone item">
                        <FiPhone style={{ color: "#FDC900", marginRight: "5px" }} />
                        {branch.phone}
                    </div>
                    <div className="email item">
                        <FiMail style={{ color: "#FDC900", marginRight: "5px" }} />
                        {email}
                    </div>
                    <div className="location item">
                        <FiMapPin style={{ color: "#FDC900", marginRight: "5px" }} />
                        {branch.address}
                    </div>
                </div>
            </div>
            {/* <div className="footer-box-item">
                <div className="footer_service">
                </div>
            </div> */}
            <div className="footer-box-item">
                <div className="navigation_footer">
                    <h1>
                        Menu
                        <hr />
                    </h1>
                    <div className="footer_menu">
                        <Link to={"/"}>Trang chủ</Link>
                        <Link to={`/application/${firstProduct?.slug}`}>Sản phẩm</Link>
                        <Link to={`/solution/${firstSolution?.slug}`}>Giải pháp</Link>
                        <Link to={"/about-us"}>Về chúng tôi</Link>
                        <Link to={"/contact-us"}>Liên hệ</Link>
                    </div>
                </div>
            </div>
            <div className="footer-box-item">
                <div className="zalo_footer">
                    <h1 className="zalo_feed">
                        Mạng xã hội
                        <hr />
                    </h1>
                    <div className="logo_list">
                        {socials.map(
                            (social) =>
                                social.link && (
                                    <div key={social.id} className="logo_item">
                                        <a href={social.link} target="_blank">
                                            <img className="logo" src={`/images/${social.type}_logo.png`} alt={social.type} />
                                        </a>
                                    </div>
                                )
                        )}
                    </div>
                    <p>
                        Liên kết với chúng tôi trên mạng xã hội - Kết nối và cập nhật thông tin mới nhất, chia sẻ câu chuyện, và tham gia
                        vào cộng đồng đa dạng.
                    </p>
                </div>
            </div>
            <div className="footer-box-item">
                <div className="signature">
                    <img src="images/logo_cc_footer.svg" alt="" />
                    <p>2023 ATE-Solutions, Inc. Terms & conditions | Privacy Policy</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
