import BaseApi from './BaseApi';
import axios from 'axios';

const username = "admin";
const password = "!@Ate-SolutionsPW12345";

class ClientMailApi extends BaseApi {
  constructor() {
    super({apiPrefix: 'client/mail'})
  }

  createContact(data) {
    const url = `${this.getApiPrefix()}/contact`; 
    const config = {
      headers: {
        Authorization: 'Basic ' + btoa(username + ':' + password)
      }
    };

    return this.instance.post(url, data, config);
  }

  createQuote(data) {
    const url = `${this.getApiPrefix()}/quote`;
    const config = {
      headers: {
        Authorization: 'Basic ' + btoa(username + ':' + password)
      }
    };

    return this.instance.post(url, data, config);
  }
}

export default new ClientMailApi();
