import axios from "axios";

class BaseApi {
  instance;
  apiPrefix;

  constructor(params) {
    this.instance = axios.create({
     baseURL: `https://backend.web.ate-solutions.com.vn`,
      // baseURL: `http://192.168.1.158:3010`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    this.apiPrefix = params?.apiPrefix;
  }

  getApiPrefix() {
    return `api/v1/${this.apiPrefix}`;
  }

  errorHandler(error) {
    if (error?.response?.data?.code === 1009) {
      window.location.href = "/";
    }
    throw error?.response || error;
  }

  async get(url, config) {
    try {
      const result = await this.instance.get(url, config);
      return result.data;
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async post(url, data, config) {
    try {
      const result = await this.instance.post(url, data, config);
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async put(url, data, config) {
    try {
      const result = await this.instance.put(url, data, config);
      return result;
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async patch(url, data, config) {
    try {
      const result = await this.instance.patch(url, data, config);
      return result;
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async del(url, config) {
    try {
      const result = await this.instance.del(url, config);
      return result;
    } catch (error) {
      this.errorHandler(error);
    }
  }

  getList = async (config) => {
    const apiUrl = this.getApiPrefix();
    return this.get(apiUrl, config);
  };

  getOne = async (id, config) => {
    const apiUrl = `${this.getApiPrefix()}/${id}`;
    return this.get(apiUrl, config);
  };

  create = async (data, config) => {
    const apiUrl = this.getApiPrefix();
    return this.post(apiUrl, data, config);
  };

  update = async (id, data) => {
    const apiUrl = `${this.getApiPrefix()}/${id}`;
    return this.put(apiUrl, data);
  };

  delete = async (id, config) => {
    const apiUrl = `${this.getApiPrefix()}/${id}`;
    return this.del(apiUrl, config);
  };
}

export default BaseApi;
