import React from "react";
import SwiperCore, { FreeMode, Navigation, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import OfficeLocationCard from "../../common/OfficeLocationCard";

const OfficesLocation = (props) => {
  const branches = props.branches;
  const cardItems = [
    {
      name: "Chi nhánh Hạ Long",
      phone: "0219 123 456",
      address:
        "Số 10, Đường Hạ Long, Bãi Cháy, Hồng Gai, TP Hạ Long, Quảng Ninh",
      url: "/images/ate-office1.jpg",
    },
    {
      name: "Chi nhánh Đà Nẵng",
      phone: "0236 987 654",
      address: "Số 20, Đường Nguyễn Văn Linh, Hòa Minh, Liên Chiểu, TP Đà Nẵng",
      url: "/images/office_1.png",
    },
    {
      name: "Chi nhánh TP. Hồ Chí Minh",
      phone: "028 555 789",
      address:
        "Số 30, Đường Nguyễn Huệ, Phường Bến Nghé, Quận 1, TP Hồ Chí Minh",
      url: "/images/ate-office1.jpg",
    },
  ];
  return (
    <div className="offices_location_wraper">
      <div className="offices_header">
        <h1 className="title">
          Chi nhánh <hr />
        </h1>
        <p className="base_text">
          Khám phá chi nhánh của chúng tôi - Đến và nhận tư vấn giải pháp chuyên
          nghiệp tại các địa điểm gần bạn. Tại chi nhánh, chúng tôi cam kết mang
          đến sự hỗ trợ tận tâm và đáp ứng nhu cầu đa dạng của khách hàng.
        </p>
      </div>
      <div className="office_slide">
        <Swiper
          effect="coverflow"
          centeredSlides={true}
          slidesPerView={"auto"}
          spaceBetween={30}
          freeMode={true}
          centeredSlidesBounds={true}
          navigation={{
            clickable: true,
          }}
          coverflowEffect={{
            slideShadows: false,
            rotate: 0,
            stretch: -60,
            depth: 100,
            modifier: 2.5,
          }}
          modules={[FreeMode, Navigation, EffectCoverflow]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className="mySwiper"
        >
          {branches?.map((item, index) => {
            return (
              <SwiperSlide key={index} onClick={() => console.log("click")}>
                <OfficeLocationCard cardItem={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default OfficesLocation;
