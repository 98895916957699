import React, { useEffect, useState, useMemo } from "react";
import Header from "./common/Header";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import HomePage from "./screens/HomPage/HomePage";
import AboutUs from "./screens/AboutUs/AboutUs";
import { Route, Routes, useLocation } from "react-router-dom";
import { ROUTER } from "../utils/router";
import ContactUs from "./screens/ContactUs/ContactUs";
import ApplicationDetail from "./screens/ApplicationDetail";
import Applicatiion from "./screens/Application/Applicatiion";
import Product from "./screens/Product/Product";
import RefContext from "../Context/RefContext";
import ClientCompanyApi from "../api/ClientCompanyApi";
import ClientApplicationApi from "../api/ClientApplicationApi";
import ClientSolutionApi from "../api/ClientSolutionApi";

const LandingPage = () => {
    const [loading, setLoading] = useState("");
    const [company, setCompany] = useState({});
    const [email, setEmail] = useState("");
    const [socials, setSocial] = useState([]);
    const { pathname } = useLocation();
    const [menuProduct, setMenuProduct] = useState([]);
    const [menuSolution, setMenuSolution] = useState([]);
    const [firstProduct, setFirstProduct] = useState({});
    const [firstSolution, setFirstSolution] = useState({});

    useEffect(() => {
        setLoading(pathname);
    }, [pathname]);

    useEffect(() => {
        getCompanyData();
    }, []);

    useEffect(() => {
        getListApplications();
        getListSolutions();
    }, []);

    const getListApplications = async () => {
        const { data } = await ClientApplicationApi.getList();
        setMenuProduct(data);
        setFirstProduct(data[0]?.children[0]);
    };

    const getListSolutions = async () => {
        const { data } = await ClientSolutionApi.getList();
        setMenuSolution(data);
        setFirstSolution(data[0]?.children[0]);
    };

    const getCompanyData = async () => {
        const { data } = await ClientCompanyApi.getCompany();
        setCompany(data);
        setEmail(data.email);
        setSocial(data.socials);
        return data;
    };
    const getMainBranch = useMemo(() => {
        const branch = company?.branches?.find((x) => x.isMainBranch === true) || {};
        return branch;
    }, [company]);

    return (
        <RefContext.Provider value={{ loading }}>
            <Header branch={getMainBranch} email={email} />
            <Navbar menuProduct={menuProduct} menuSolution={menuSolution} />
            <Routes>
                <Route path={ROUTER.HOME} element={<HomePage />} />
                <Route path={ROUTER.ABOUT} element={<AboutUs />} />
                <Route path={ROUTER.CONTACT} element={<ContactUs />} />
                <Route path={ROUTER.APPLICATION_DETAIL} element={<ApplicationDetail />} />
                <Route path={ROUTER.SOLUTION_DETAIL} element={<Applicatiion />} />
                <Route path={ROUTER.PRODUCT_DETAIL} element={<Product />} />
            </Routes>
            <Footer 
                branch={getMainBranch} 
                email={email} 
                socials={socials} 
                firstProduct={firstProduct} 
                firstSolution={firstSolution} 
            />
        </RefContext.Provider>
    );
};

export default LandingPage;
