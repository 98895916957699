import React, { useEffect } from "react";
import { Menu } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
// import ProductMenu from "../../data/ProductMenuV2.json";

import ClientApplicationApi from "../../api/ClientApplicationApi";
import ClientSolutionApi from "../../api/ClientSolutionApi";
  
// submenu keys of first level
const rootSubmenuKeys = ["subProduct", "subSolutions", "abouts-us", "contact-us"];
const SideMenu = ({ setOpenSideMenu }) => {
    const [product, setProducts] = useState([]);
    const [productMenu, setProductMenu] = useState([]);
    const [solution, setSolutions] = useState([]);
    const [solutionMenu, setSolutionMenu] = useState([]);

    const getListProducts = async() => {
        const  ProductData = await ClientApplicationApi.getList();
        setProducts(ProductData);
    };

    const getListSolutions = async() => {
        const  SolutionData = await ClientSolutionApi.getList();
        setSolutions(SolutionData);
    };

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const items = [
        getItem("Product", "subProduct", <img src="images/ico_product.svg" alt="ico" />, productMenu),
        getItem("Solutions", "subSolutions", <img src="images/ico_user_group.svg" alt="ico" />, solutionMenu),
        getItem(<Link to={"/about-us"}>About us</Link>, "abouts-us", <img src="images/ico_user_circle.svg" alt="ico" />),
        getItem(<Link to={"/contact-us"}>Contact us</Link>, "contact-us", <img src="images/ico_paper.svg" alt="ico" />)
        ];

    useEffect(() => {
        try {
            const dataArrayProductMenu = product.data;
            const updatedProductMenu = dataArrayProductMenu?.reduce((productMenu, item) => {
                const subMenu = item?.children?.reduce(
                (subMenu, itemSubmenu) => [
                    ...subMenu,
                    getItem(
                    <Link to={"/product/" + itemSubmenu.slug}>{itemSubmenu.name} </Link>,
                    itemSubmenu?.name,
                    null
                    )
                ],
                []
                );
                return [...productMenu, getItem(item.name, item.name, null, subMenu)];
            }, []);
            setProductMenu(updatedProductMenu);
            // console.log('SolutionsMenu', updatedSolutionMenu);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [product]);
    
    useEffect(() => {
        try {
            const dataArraySolutionMenu = solution.data;
            const updatedSolutionMenu = dataArraySolutionMenu?.reduce((productMenu, item) => {
                const subMenu = item?.children?.reduce(
                (subMenu, itemSubmenu) => [
                    ...subMenu,
                    getItem(
                    <Link to={"/solution/" + itemSubmenu.slug}>{itemSubmenu.name} </Link>,
                    itemSubmenu?.name,
                    null
                    )
                ],
                []
                );
                return [...productMenu, getItem(item.name, item.name, null, subMenu)];
            }, []);
            setSolutionMenu(updatedSolutionMenu);
            // console.log('SolutionsMenu', updatedSolutionMenu);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [solution]);

    useEffect(() => {
        const fetchProductMenu = async () => {
            await getListProducts();
        };

        const fetchSolutionMenu = async () => {
            await getListSolutions();
        };

        fetchProductMenu();
        fetchSolutionMenu();
    }, []);

    const [openKeys, setOpenKeys] = useState([""]);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    return (
        <Menu
            onClick={() => setOpenSideMenu(false)}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{
                width: 300,
                backgroundColor: "#051829",
            }}
            items={items}
            theme="dark"
        />
    );
};
export default SideMenu;
