import { Checkbox, Input, Radio, notification } from "antd";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import SelectBox from "../common/SelectBox";
import TextArea from "antd/es/input/TextArea";

// Api
import ClientMailApi from "../../api/ClientMailApi";

const Chat = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [dataForm, setDataForm] = useState({});

  const handleChange = (name, value) => {
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    const isValid =
      !!Object.keys(dataForm).length &&
      Object.keys(dataForm).every((key) => !!dataForm[key]);
    if (isValid) {
      setIsLoading(true);
      const { data } = await ClientMailApi.createContact(dataForm);
      setIsLoading(false);
      setDataForm({
        name: "",
        email: "",
        company: "",
        jobTitle: "",
        message: "",
        type: "contact"
      });
      api.destroy(1);
      api.success({
        key: 1,
        message: data.message,
        placement: "topRight",
      });
    }
  };

  useImperativeHandle(ref, () => ({
    dataForm,
    setDataForm
  }));

  return (
    <div className="getaquote-wraper">
      <div className="form-input">
        <div className="input_group">
          <label className="required label">Họ và tên</label>
          <Input
            className="input"
            type="text"
            value={dataForm.name}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Email</label>
          <Input
            type="email"
            value={dataForm.email}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Số điện thoại</label>
          <Input
            className="input"
            type="number"
            value={dataForm.jobTitle}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("jobTitle", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Công ty</label>
          <Input
            className="input"
            type="text"
            value={dataForm.company}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("company", e.target.value)}
          />
        </div>
        <div className="input_group">
          <label className="required label">Chi tiết về yêu cầu của bạn</label>
          <TextArea
            className="input"
            value={dataForm.message}
            rows={2}
            placeholder="Nhập thông tin..."
            onChange={(e) => handleChange("message", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Chat);
