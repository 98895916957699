import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ClientBannerApi from "../../../api/ClientBannerApi";

const AboutHeader = () => {
  const [banner, setBanner] = useState({});

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = async () => {
    const { data } = await ClientBannerApi.getCBannerAboutUs();
    setBanner(data);
  };

  return (
    <div
      className="about_header"
      style={{
      //  backgroundImage: `url(${banner?.image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <h1>Về chúng tôi</h1> */}
      <p>
        {banner?.desc}
      </p>
    </div>
  );
};

export default AboutHeader;
